.searchResult {
    list-style: none;
    padding: 0;
    width: 100%;
    position: absolute;
    z-index: 99;
}

.searchResult li {
    background: white;
    border: solid 1pt #ced4da;
    border-top: none;
}

.searchResult li:hover {
    background: darkgreen;
    color: white;
}

.searchResult li:hover {
    cursor: pointer;
}